/*eslint-disable */
<template>
  <b-container fluid class="user_lead_gide p-0">
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img src="../assets_gide/img/logo/GideWhiteNRed.jpeg" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
             <img :src="eventDetail.event_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="eventDetail.event_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="8" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card p-2">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="eventDetail.event_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff; position:relative;' class="lead_add_form">
        <iq-card class="p-2" >
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <!-- <img :src="eventDetail.event_img" style="width: 100%; padding-top: 10px" /> -->
            <h3>
              {{ cvCardTitle }}
            </h3>
            </template>
            <template v-slot:body>
            <ValidationObserver ref="pbaForm">
              <form action="#">
                <div class="form-row">
                  <div v-bind:class = "(operatingsystem == 'web') ? 'gide_scroll_box row m-0' : 'gide_box row m-0' ">
                    <div class="col-md-12 mb-3">
                      <ValidationProvider
                          name="Create Activity as"
                          rules="required"
                          v-slot="{ errors }">
                          <multiselect
                            v-model="vmUsertype"
                            :options="vmUserRegLeadTypeOptions"
                            track-by="name"
                            label="name"
                            :placeholder="cvUsrlTypeLabel"
                            :tag-placeholder="cvUsrlTypeLabel"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationusrl_name">
                        {{ cvUsrlNameLabel }}</label>
                        <ValidationProvider
                          name="Your name"
                          rules="required"
                          v-slot="{ errors }">
                        <input v-model="vmUserRegLeadFormData.usrl_name" type="text" class="form-control" required />
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationusrl_email">
                        {{ cvUsrlEmailLabel }}</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                          <input v-model="vmUserRegLeadFormData.usrl_email" type="text" class="form-control" required />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-4 col-md-12 col-lg-6 col-12 mb-3">
                      <label for="validation_city">{{cvUsrlCountryCodeLabel}}</label>
                          <!-- <input v-model="vmUserRegLeadFormData.usrl_city" type="text" class="form-control mr-3" placeholder="Enter the city" required /> -->
                          <ValidationProvider
                            name="Select one"
                            rules="required"
                            v-slot="{ errors }">
                              <select v-model="vmUserRegLeadFormData.user_country" class="form-control w-100">
                              <template class="w-100">
                                <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                  {{country.country_name}} (+ {{country.country_phone_code}})
                                </option>
                              </template>
                            </select>
                            <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                    </div>
                    <div class="col-sm-8 col-md-12 col-lg-6 col-12 mb-3">
                      <ValidationProvider
                          name="Contact number"
                          rules="required"
                          v-slot="{ errors }">
                      <label for="validationusrl_contact_no">
                        {{ cvUsrlContactNoLabel }}</label>
                      <input v-model="vmUserRegLeadFormData.usrl_contact_no" type="tel" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="validationusrl_city">
                        {{ cvUsrlCityLabel }}</label>
                        <ValidationProvider
                          name="City name"
                          rules="required"
                          v-slot="{ errors }">
                      <input v-model="vmUserRegLeadFormData.usrl_city" type="text" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="validationusrl_state">
                        {{ cvUsrlStateLabel }}</label>
                        <ValidationProvider
                          name="State name"
                          rules="required"
                          v-slot="{ errors }">
                      <input v-model="vmUserRegLeadFormData.usrl_state" type="text" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="validationusrl_country">{{ cvUsrlCountryLabel }}</label>
                        <ValidationProvider
                          name="Country"
                          rules="required"
                          v-slot="{ errors }">
                          <select v-model="vmUserRegLeadFormData.usrl_country" class="form-control">
                            <template>
                              <option v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                {{country.country_name}}
                              </option>
                            </template>
                          </select>
                          <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                      </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationusrl_org_name">
                        {{ cvUsrlOrgNameLabel }}</label>
                        <ValidationProvider
                          name="Organisation name"
                          rules="required"
                          v-slot="{ errors }">
                      <input v-model="vmUserRegLeadFormData.usrl_org_name" type="text" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationusrl_org_website">
                        {{ cvusrl_org_websiteLabel }}</label>
                        <ValidationProvider
                          name="Website name"
                          rules="required"
                          v-slot="{ errors }">
                      <input v-model="vmUserRegLeadFormData.usrl_org_website" type="text" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationusrl_about_myself">
                        {{ cvUsrlAboutMyselfLabel }}</label>
                      <textarea v-model="vmUserRegLeadFormData.usrl_about_myself" type="text" class="form-control"
                        required maxlength="150"></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group" style="width:100%;text-align:right">
                        <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                          <b-spinner label="Spinning"></b-spinner>
                        </button>
                        <button type="button" class="btn btn-primary" @click="userRegLeadAdd()">
                          {{ cvSubmitBtn }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
            <div class="sign-info">
              <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
              <ul class="iq-social-media pt-4">
                <li>
                  <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
     <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .user_lead_gide {
    .text-danger {
      color: #dc3545 !important;
    }
    .sign-info{
      margin-top:0px;
      border: 0px;
      padding-top:0px;
      display: flow-root;
    }
    .user_lead_subhead{
      color: var(--iq-body-text);
      font-size:14px ;
    }
    .form-control {
      height: 45px;
      line-height: 45px;
      background: transparent;
      border: 1px solid #f1f1f1;
      font-size: 14px;
      border-radius: 10px;
    }
    .lead_add_form::-webkit-scrollbar {
      display: none;
    }
    .lead_add_form{
      .table thead th{
        border-bottom:0px ;
      }
      .card-title{
        color:var(--iq-title-text);
      }
      label{
        color: var(--iq-body-text)
      }
      .iq-card{
        margin: 0px;
        border: none;
        box-shadow: none;
        .iq-card-header{
          padding: 0px;
          border: 0px;
        }
        .iq-card-body{
          padding: 0px;
        }
      }
    }
  }
  @media screen and (min-width:576px){
  .gide_scroll_box{
    overflow: auto;
    height: calc(100vh - 320px);
    max-height: 550px;
  }
  .gide_box{
    overflow: auto;
    height: calc(100vh - 320px);
    max-height: 550px;
  }
}
</style>
<style lang="scss" scoped>
textarea {
  min-height: 150px;
  line-height: 27px !important;
}
</style>

<script>
import { UserRegLeads } from "../FackApi/api/UserRegLead.js"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"
import { Countries } from "../FackApi/api/country"
import { events } from "../FackApi/api/events" // For getting event details with module_obj_id in query params
import userRolesJson from "../FackApi/json/UserRoles.json"

export default {
  name: "UserRegLeadAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider,
    Multiselect
  },
  data () {
    return {
      cvCardTitle: "Contact Us",
      cvCardSubHeader: "Are your a University, School, Ed-Tech, Educator, Counsellor, Organisation",
      cvSubmitBtn: "Submit",
      cvUsrlTypeLabel: "Whom do you represent ?",
      cvUsrlNameLabel: "Name",
      cvUsrlEmailLabel: "Official Email",
      cvUsrlContactNoLabel: "Contact no",
      cvUsrlCityLabel: "City",
      cvUsrlStateLabel: "State",
      cvUsrlCountryCodeLabel: "Country code",
      cvUsrlCountryLabel: "Country",
      cvUsrlOrgNameLabel: "Organization Name",
      cvusrl_org_websiteLabel: "Website",
      cvUsrlAboutMyselfLabel: "Tell us a little bit about yourself",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Contact Us",
      vmUserRegLeadFormData: Object.assign({}, this.initFormData()),
      vmUserRegLeadDesc: null,
      vmUsertype: null,
      cvCountryOptions: [],
      source: "",
      module_name: "",
      module_obj_id: "",
      // eventDetail: {},
      module_type: "",
      eventDetail: { event_img: "https://gide-assets-prod.s3.amazonaws.com/EVENT/EVEcff7a6d503ee443b86553f529683d4e3/8a48d740-2ee6-11ef-864f-03eb2ac38166.png", event_desc: "Join HSM Connect, a virtual Career Counselling & University Fair scheduled on July 20 & 21, where students and parents from different regions can connect & interact with 20+ universities from across the globe. Students & parents will get to learn about academic programs, scholarships, campus life, entry requirements, timelines & much more." },
      operatingsystem: window.__DEVICE_OS__,
      vmUserRegLeadTypeOptions: [
        {
          name: userRolesJson.USERROLE11115,
          value: "USERROLE11115"
        },
        {
          name: userRolesJson.USERROLE11117,
          value: "USERROLE11117"
        },
        {
          name: userRolesJson.USERROLE11116,
          value: "USERROLE11116"
        },
        {
          name: userRolesJson.USERROLE11112,
          value: "USERROLE11112"
        },
        {
          name: userRolesJson.USERROLE11118,
          value: "USERROLE11118"
        }
      ],
      userRolesJson: userRolesJson
    }
  },
  async mounted () {
    // if sourceData is available then store in source else take user defined value to store in source
    this.source = this.$route.query.source ? this.$route.query.source : this.sourceData

    // Capturing the details of event/fare from the query params
    if (this.$route.query.module_name) {
      this.module_name = this.$route.query.module_name
      this.cvCardTitle = "Contact Us for " + this.$route.query.module_name
    }

    if (this.$route.query.module_id) {
      this.module_obj_id = this.$route.query.module_id
      if (this.module_obj_id.includes("EVE") || this.module_obj_id.includes("APP")) {
        this.getEventDetail()
      }
    }

    if (this.$route.query.module_type) {
      this.module_type = this.$route.query.module_type
      // Adding the module_type in form data so that we can decide whether to make an entry into the rsvp table
      this.vmUserRegLeadFormData.module_type = this.module_type
    }

    this.countryDistinctList()
  },
  computed: {
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "usrl_type": "",
        "usrl_name": "",
        "usrl_email": "",
        "usrl_contact_no": "",
        "usrl_city": "",
        "usrl_state": "",
        "usrl_country": "",
        "usrl_org_name": "",
        "usrl_org_website": "",
        "usrl_about_myself": ""
      }
    },
    /**
       * countryDistinctList
       */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        const findCountryIndex = this.cvCountryOptions.findIndex
        if (findCountryIndex >= 0) {
          this.vmUserRegLeadFormData.user_country = this.cvCountryOptions[findCountryIndex]
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * getEventDetail
     */
    async getEventDetail () {
      const resp = await events.eventViewPublic(this, this.module_obj_id)
      if (resp.resp_status) {
        this.eventDetail = resp.resp_data.data
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserRegLeadFormData) {
          if (!this.vmUserRegLeadFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * userRegLeadAdd
     */
    async userRegLeadAdd () {
      let valid = await this.$refs.pbaForm.validate()
      if (!valid) {
        return
      }

      this.vmUserRegLeadFormData.usrl_type = this.vmUsertype.value
      this.vmUserRegLeadFormData.usrl_country = this.vmUserRegLeadFormData.usrl_country.country_name
      this.vmUserRegLeadFormData.usrl_contact_no = this.vmUserRegLeadFormData.user_country.country_phone_code + this.vmUserRegLeadFormData.usrl_contact_no
      this.vmUserRegLeadFormData.source = this.source

      // Adding the fields - module_name, module_obj_id into form data which is necessary for making the event_rsvp entry
      if (this.module_type == "EVENT") {
        this.vmUserRegLeadFormData.module_name = this.module_name
        this.vmUserRegLeadFormData.module_obj_id = this.module_obj_id
      }

      try {
        this.cvLoadingStatus = true
        let userRegLeadAddResp = await UserRegLeads.userRegLeadAdd(this, this.vmUserRegLeadFormData)
        await ApiResponse.responseMessageDisplay(this, userRegLeadAddResp)

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUserRegLeadAddModal", userRegLeadAddResp.resp_data)
        }
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
